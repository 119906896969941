$primary-color: #cb0b0b;
$secondary-color: #121212;
$hero-bkg:#f3f3f3;
$featured-image: url(/images/home-dragon.png);
$icon-color:$primary-color;
$header-background:#ffe400;

$page-header-bkg:$primary-color;
$page-header:#FFF;

$toggle-icon-bar:white;
$line-color:#ededed;

$home-search-bkg:#ffe400;
$home-search-text:#000;
$home-search-submit-bkg:$primary-color;
$home-search-submit-txt:#FFF;


/* header and navbar */
header{
  background-color: $header-background;
}

.navbar{
  margin-bottom:0;
  min-height:70px;
}
.navbar-brand {
  padding: 10px;
  img {
    max-width:200px;
  }
}
/* @media screen and (max-width: 1000px){
  .navbar-brand img{
    max-width:160px;
  }
}
@media screen and (max-width: 430px) {
  .navbar-brand img {
    max-width:140px;
  }
  .navbar {
    min-height:70px;
  }
} */
.navbar-nav>li>a {
  padding-top:20px;
  color: $secondary-color;
  text-transform:uppercase !important;
}
.nav>li>a:focus, .nav>li>a:hover{
  background-color : transparent;
}
.navbar-toggle {
  background-color:$secondary-color;
  margin-right:0;

  .icon-bar {
    background-color: $toggle-icon-bar;
  }
}

/* body, main and basic styles */
body{
  color:#666;
  font:16px "Lato", sans-serif;
  line-height: 23px;
  word-wrap: break-word;
  font-weight: 300;
}
a{
  color:$primary-color;
}
a:hover, a:active{
  color:$secondary-color;
}
h1, h2, h3, h4, h5, h6{
  color : $secondary-color;
  text-transform: uppercase;
}
ul{
  list-style: none;
  -webkit-padding-start:0;
}
p {
  margin:0 0 20px;
}
input[type="text"], input[type="email"], input[type="search"], input[type="password"], input[type="tel"], input[type="url"], input[type="date"], textarea{
  padding: 10px;
  border: 1px solid rgba(0, 0, 0, 0.05);
  width: 100%;
  margin: 0 0 20px;
  background-color: #f9f9f9;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  border-radius: 3px;
}
input[type="reset"], input[type="button"], input[type="submit"]{
  background-color: $primary-color;
  border:1px solid $primary-color;
  color: #FFF;
  font-weight: 700;
  cursor: pointer;
  margin-bottom: 30px;
  text-transform: uppercase;
  display: block;
  text-align: center;
  height: 40px;
  padding: 0 20px;
  margin-top: 5px;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  border-radius: 3px;
}
main {
  padding:30px 0 40px;
}
/* blog posts */
.post {
  padding: 0 20px 20px;
  margin-bottom: 40px;
  border-bottom: 1px solid $line-color;
}

.meta {
  padding:10px;
  margin-bottom :10px;
  border-bottom:1px solid $line-color;
  font-size:13px;
  background-color:#f7f7f7;
  img {
    max-width:30px;
  }
}
.author, .date {
  padding:0 10px 0 5px;
}
p.title {
  font-size: 30px;
  line-height: 37px;
  margin-bottom :0;
  padding:10px 10px 15px;
  background-color:#1a1a1a;
  border-bottom:1px solid $line-color;
  color:#FFF;
  a{
    color:#FFF;
  }
}
.tip-excerpt, .tip{
  padding: 0 0 20px;
  margin-bottom: 30px;
  border-bottom: 1px solid $line-color;
  text-align:center;
  p.title {
    text-align:left;
  }
  img{
    text-align:center;
    margin:20px auto 5px;
    border:2px solid black;
  }
}
.post-excerpt{
  padding: 0 0 20px;
  margin-bottom: 40px;
  border-bottom: 1px solid $line-color;
  .icon{
    display:inline-block;
    float:left;
    width:10%;
    font-size:4rem;
    margin-right: 2%;
    text-align:center;
  }
  .excerpt{
    display:inline-block;
    width:88%;
  }
  a.button{
    font-weight: bold;
    text-transform: uppercase;
    color : $primary-color;
    border-bottom: 1px solid $primary-color;
    letter-spacing:.1em;
  }
}
.pagination {
  display: block;
  min-height: 50px;
  text-align: center;

  a {
    color: $primary-color;
    border: 1px solid $line-color;
    padding: 5px 10px;
    text-align: center;
    margin: 5px;
    border-radius: 4px;
    display:inline-block;
  }

  a:hover {
    border: 1px solid $primary-color;
    text-decoration: none;
    font-weight: bold;
  }

}
/* sidebar */
aside h3.widget-title, aside h3, aside h2.title {
  font-size: 20px;
  line-height: 23px;
  padding-top:0;
  padding-bottom: 15px;
  margin-bottom:20px;
  font-weight: normal;
  margin-top:0;
  color:#333;
  letter-spacing: 2px;
  text-transform: uppercase;
  border-bottom: 1px solid $line-color;
}
/* footer */
footer{
  background-color:$secondary-color;
  padding: 20px 0 20px;
  color:#a5a5a5;
  font-size: 14px;
  line-height: 21px;
  text-align:left;
  .container{
    margin-left: auto;
    max-width: 85%;
  }
  a {
    color:#a5a5a5;
    padding-left:10px;
  }
  a:hover{
    color:#cdcdcd;
  }
}

.back-to-top {
  position: fixed;
  bottom: 10px;
  right: 10px;

  a {
    display:block;
    background-color: $page-header-bkg;
    color: #FFF;
    width: 40px;
    height: 40px;
    border-radius: 3px;
    font-size: 16px;
    line-height: 40px;
    text-align: center;
  }
}
@media screen and (max-width:830px) {
  footer .copyright{
    padding-bottom: 10px;
    display: block;
  }
}
/*section specific code */
/*styles for page-header partial*/
section.page-title-wrap {
  background-color: $page-header-bkg !important;
  padding               : 10px 0 20px;
  text-align            : center;
  background-attachment : fixed;
  background-position   : center center;
  background-size       : cover;
  margin-top            : -1px;

  h1.page-title {
    font-size: 30px;
    line-height: 37px;
    color: $page-header;
    font-weight: 700;
    margin:0 auto;
  }
}
/*hero only needed when including the hero partial*/
section.hero{
  text-align:center;
  background-color: $hero-bkg;
  height:400px;
  background-image:url(/images/city-bg.png);
  background-position: center bottom;
  background-repeat:repeat-x;
  padding:0;
  background-size:contain;
  .inner{
    text-align:center;
    height:400px;
    background-color: transparent !important;
    background-image:$featured-image;
    background-size: contain;
    background-position: center bottom;
    background-repeat:no-repeat;
  }
}
@media screen and (max-width:1300px) {
  section.hero {
    height: 400px;
    .inner{
      height:400px;
    }
  }
}
@media screen and (max-width:885px) {
  section.hero {
    height: 280px;
    .inner{
      height:280px;
      background-size:cover;
    }
  }
}
/* only needed when using the signup-message partial */
#home-intro{
  height:240px;
  text-align:center;
  background-color:$home-search-bkg;
  .promotional_bar_content{
    text-align:center;
    height:240px;
    max-width: 1170px;
    margin: auto;
    padding:40px 10px;
    h3{
      display:none;
    }
    p {
      padding-bottom: 10px;
      display: block;
      margin: 20px auto 10px;
    }
    img{
        display:block;
        margin: 20px auto;
        text-align:center;
        max-width:100%;
    }

    a{
      background-color: $primary-color;
      border: 1px solid $primary-color;
      color: white;
      padding: 10px 20px;
      border-radius: 3px;
    }

  }
}

/*home search only needed when including the search-jobs partial*/
section#home-search{
  text-align:center;
  background:#f7f7f7;
  padding: 20px 0 30px;
  h3{
    color:$secondary-color;
    padding-top: 0;
    font-size: 2em;
    font-weight: 700;
  }
  form{
    max-width: 75%;
    text-align: center;
    margin: auto;
    padding:0;
  }
  input[type="text"]{
    width:37%;
  }
  input[type="submit"]{
    background-color:$home-search-submit-bkg;
    border:1px solid $home-search-submit-bkg;
    color:$home-search-submit-txt;
    display:inline-block;
    height:45px;
  }
}
@media screen and (max-width:782px) {
  section#home-search form {
    max-width : 90%;
    input[type="text"]{
      width:100%;
    }
  }
}
/*by-sector, sectors only needed when including sector-module partial in sidebar
by-location, locations only needed when including city-module partial in sidebar*/
aside .by-sector h3, aside .by-location h3{
  font-size: 16px;
  line-height: 23px;
  padding-bottom: 15px;
  font-weight: normal;
  margin-top: 0;
  color: #333;
  letter-spacing: 2px;
  text-transform: uppercase;
}
aside ul.sectors li, aside ul.locations li {
  flex-basis: 100%;
  padding-bottom:5px;
  a i{
    color: $icon-color !important;
  }
}

/*home tips*/
.home-tips{
  padding: 0;
  display: flex;
  max-width:1170px;
  margin:10px auto 30px;
  text-align:center;
  flex-direction:row;
  flex-wrap:wrap;
  justify-content: space-between;
  section.tip-excerpt-home{
    display: flex;
    flex-basis:24%;
    margin:10px 0;
    img{
      flex-basis:24%;
      max-width:100%;
      border: 2px solid black;
      margin-bottom:20px;
    }
  }
}
@media screen and (max-width:782px) {
  .home-tips {
    section.tip-excerpt-home{
      flex-basis:80%;
      justify-content: center;
      margin: auto;
      text-align: center;
    }
  }
}
/*home listings*/
.jobs {
  padding: 0;
  display: flex;
  max-width:1170px;
  margin:10px auto 30px;
  text-align:center;
  flex-direction:row;
  flex-wrap:wrap;
  justify-content: space-between;
  section{
    flex-basis:24%;
    padding:1.5%;
    margin:10px 0;
    background-color:#f7f7f7;
    text-align:left;
    border-radius: 10px;
  }
  h2.Title {
    font-size: 20px;
    max-height: 52px;
    line-height:26px;
    overflow: hidden;
    margin:0 auto 5px;
    a {
      color: $secondary-color;
    }
  }
  a.Company{
    color: $primary-color;
    font-size:14px;
  }
  .TimePosted {
    display: block;
    color:#999;
    font-size:11px;
  }
  .snippet {
    font-size:12px;
    a.More{
      display:block;
    }
  }
}

@media screen and (max-width:782px) {
  .jobs {
    section{
      flex-basis:80%;
      justify-content: center;
      margin: 0 auto 20px;
    }
  }
}